export const plans = [
  {
    id: 'online',
    name: 'Fatigue Super Conference Online Access Only',
    oldPrice: 119,
    price: 59,
    requiresAddress: false,
    soldOut: false,
  },
  {
    id: 'onlineflash',
    name: 'Fatigue Super Conference Online Access + Flash Drive',
    oldPrice: 139,
    price: 99,
    requiresAddress: true,
    soldOut: true,
  },
  {
    id: 'flash',
    name: 'Fatigue Super Conference Flash Drive Only',
    oldPrice: 139,
    price: 99,
    requiresAddress: true,
    soldOut: true,
  },
];

export default plans;
