import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Container from '../layout/Container';
import StripeStyle from './StripeStyle';
import Loader from './Loader';
import Button from './Button';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { useSiteMetaContext } from '../context/SiteMetaContext';
import { useUserContext } from '../context/UserContext';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

const Form = styled.form`
  width: 100%;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    width: 50%;
  `};
`;

const Footer = styled.p`
  font-size: ${({ theme }) => theme.font.sizes.xs};
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.lightGray};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  font-size: ${({ theme }) => theme.font.sizes.xxs};
`}
`;

const Disclaimer = styled.p`
  line-height: 150%;
  font-size: ${({ theme }) => theme.font.sizes.xs};
  font-weight: 400;
  margin: 0 auto;
  color: ${({ theme }) => theme.colours.lightGray};
  padding-bottom: ${({ theme }) => theme.sizes.lvl1};

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
  font-size: ${({ theme }) => theme.font.sizes.xxs};
`}
`;

const FormTitle = styled.h3`
  color: ${({ theme }) => theme.colours.darkGray};
  font-family: ${({ theme }) => theme.font.families.body};
  margin: 0;
  padding: 0;
  font-weight: 400;
  width: max-content;
`;

const PaymentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.sizes.lvl5};
`;

const SimpleRow = styled.div`
  height: 100%;
  max-height: 100%;
  width: max-content;
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    font-size: ${({ theme }) => theme.font.sizes.md};
    padding-right: ${({ theme }) => theme.sizes.lvl1};
    color: ${({ theme }) => theme.colours.lightGray};
    padding-bottom: ${({ theme }) => theme.sizes.lvl1};
  }
`;

const Wrapper = styled.div`
  width: ${({ theme }) => theme.sizes.lvl4};
  padding-right: ${({ theme }) => theme.sizes.lvl1};
`;

const PaymentForm = ({
  masterdardImage,
  amexImage,
  discoverImage,
  visaImage,
}) => {
  const { title } = useSiteMetaContext();
  const stripe = useStripe();
  const elements = useElements();
  const { user, firebase, selectedPlan } = useUserContext();

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  // Handle card actions like 3D Secure
  const handleCardAction = async (payment, docId) => {
    const { error, paymentIntent } = await stripe.handleCardAction(
      payment.client_secret
    );
    if (error) {
      alert(error.message);
      payment = error.payment_intent;
    } else if (paymentIntent) {
      payment = paymentIntent;
    }

    await firebase
      .customerStripePayments(user.uid)
      .doc(docId)
      .set(payment, { merge: true });

    setLoading(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!event.target.reportValidity()) {
      return;
    }
    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const billing_details = selectedPlan.requiresAddress
        ? {
            name: `${user.firstName} ${user.lastName}`,
            address: user.address
              ? {
                  city: user.address.city,
                  country: user.address.country,
                  line1: user.address.line1,
                  line2: user.address.line2,
                  postal_code: user.address.zip,
                  state: user.address.region,
                }
              : {},
          }
        : { name: `${user.firstName} ${user.lastName}` };

      const { setupIntent, error } = await stripe.confirmCardSetup(
        user.setup_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details,
          },
        }
      );
      if (error) {
        setError(error);
        setLoading(false);
        return;
      }

      await firebase
        .customer(user.uid)
        .collection('payment_methods')
        .add({ id: setupIntent.payment_method });

      const data = {
        product_id: selectedPlan.id,
        payment_method: setupIntent.payment_method,
        description: `${selectedPlan.name} - ${user.email}`,
        status: 'new',
      };

      const paymentDocRef = await firebase
        .customerStripePayments(user.uid)
        .add(
          selectedPlan.requiresAddress
            ? { ...data, shipping: billing_details }
            : data
        );

      firebase
        .customerStripePayments(user.uid)
        .doc(paymentDocRef.id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const payment = doc.data();
            if (!payment.status) return;

            if (payment.status === 'requires_action') {
              handleCardAction(payment, doc.id);
            }
            if (payment.status !== 'new') setLoading(false);
          }
        });
    } catch (e) {
      console.error('Error making payment', e);
      setError('Error making payment');
      setLoading(false);
    }
  };

  return (
    <Container>
      {user.setup_secret && (
        <Form onSubmit={onSubmit}>
          <PaymentRow>
            <FormTitle className="h5">PAYMENT INFO</FormTitle>

            <SimpleRow>
              <Wrapper>
                <Img fluid={masterdardImage} />
              </Wrapper>
              <Wrapper>
                <Img fluid={amexImage} />
              </Wrapper>
              <Wrapper>
                <Img fluid={discoverImage} />
              </Wrapper>
              <Wrapper>
                <Img fluid={visaImage} />
              </Wrapper>
            </SimpleRow>
          </PaymentRow>
          <CardElement />
          {!!error && <div>{error.message}</div>}
          <SimpleRow>
            <span className="material-icons">https</span>
            <Disclaimer>
              All payments are secured by 256-bit encryption
            </Disclaimer>
          </SimpleRow>

          <Button fluid={true} type="submit" disabled={!stripe && !isLoading}>
            Complete my purchase!
          </Button>

          <Footer>
            Please note payment will show as Conscious Psychology Ltd
          </Footer>
        </Form>
      )}
      {(!!isLoading || !user.setup_secret) && <Loader />}
    </Container>
  );
};

PaymentForm.propTypes = {
  masterdardImage: PropTypes.object,
  amexImage: PropTypes.object,
  discoverImage: PropTypes.object,
  visaImage: PropTypes.object,
};

PaymentForm.defaultProps = {
  masterdardImage: null,
  amexImage: null,
  discoverImage: null,
  visaImage: null,
};

const PaymentStripe = ({
  masterdardImage,
  amexImage,
  discoverImage,
  visaImage,
}) => {
  return (
    <>
      <StripeStyle />
      <Elements stripe={stripePromise}>
        <PaymentForm
          masterdardImage={masterdardImage}
          amexImage={amexImage}
          discoverImage={discoverImage}
          visaImage={visaImage}
        />
      </Elements>
    </>
  );
};

PaymentStripe.propTypes = {
  masterdardImage: PropTypes.object,
  amexImage: PropTypes.object,
  discoverImage: PropTypes.object,
  visaImage: PropTypes.object,
};

PaymentStripe.defaultProps = {
  masterdardImage: null,
  amexImage: null,
  discoverImage: null,
  visaImage: null,
};

export default PaymentStripe;
